"use client";

import React, { useEffect, useState } from "react";
import Image from "next/image";
import { StyledFloatingButton, StyledWidgetNav } from "./widgetNavbar.styles";
import { IPlatform, IPlugin } from "../../types";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useInView } from "react-intersection-observer";
import { CustomLink } from "../link/link.comp";
import { trackEvent } from "@/lib/track";

interface IWidgetNavbarProps {
  widget: IPlugin;
  platform?: IPlatform;
  setFormPopupOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WidgetNavbar = ({
  widget,
  platform,
  setFormPopupOpen,
}: IWidgetNavbarProps) => {
  const pathname = usePathname();
  const [showTemplatesTab, setShowTemplatesTab] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const [isBrackets, setIsBrackets] = useState<boolean>(false);
  const [shake, setShake] = useState(false);

  async function getWidgetTemplates() {
    try {
      const res = await fetch(
        `/api/templates?componentType=${widget.componentType}`
      );
      const data = await res.json();

      if ((data?.data || []).length > 0) {
        setShowTemplatesTab(true);
      }
    } catch (e) {}
  }

  useEffect(() => {
    getWidgetTemplates();
    setLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const overviewPath = platform?.title
    ? `/widgets/${widget.slug}/${platform.slug}`
    : `/widgets/${widget.slug}`;
  const examplesPath = platform?.title
    ? `/widgets/${widget.slug}/${platform.slug}/examples`
    : `/widgets/${widget.slug}/examples`;
  const templatesPath = platform?.title
    ? `/widgets/${widget.slug}/${platform.slug}/templates`
    : `/widgets/${widget.slug}/templates`;
  const howToAddPath = platform?.title
    ? `/widgets/${widget.slug}/${platform.slug}/how-to-add`
    : `/widgets/${widget.slug}/how-to-add`;

  function renderNavContent(isFixed?: boolean) {
    return (
      <div className="links">
        {isFixed && (
          <Link href={overviewPath} className="logo">
            <Image
              src={widget.logo}
              alt={`${widget.name} ${
                platform?.title ? `for ${platform.title}` : ""
              } logo`}
              width={30}
              height={30}
            />
            {widget.name}
          </Link>
        )}
        <ul>
          <li>
            <Link
              className={
                pathname === `/widgets/${widget.slug}` ||
                pathname === `/widgets/${widget.slug}/${platform?.slug}`
                  ? "active"
                  : ""
              }
              href={overviewPath}
            >
              Overview
            </Link>
          </li>
          {widget.examples?.length > 0 && (
            <li className="examples">
              <Link
                className={
                  pathname === `/widgets/${widget.slug}/examples` ||
                  pathname ===
                    `/widgets/${widget.slug}/${platform?.slug}/examples`
                    ? "active"
                    : ""
                }
                href={examplesPath}
              >
                Examples
              </Link>
            </li>
          )}
          {showTemplatesTab && (
            <li className="templates">
              <Link
                className={
                  pathname === `/widgets/${widget.slug}/templates` ||
                  pathname ===
                    `/widgets/${widget.slug}/${platform?.slug}/templates`
                    ? "active"
                    : ""
                }
                href={templatesPath}
              >
                Templates
              </Link>
            </li>
          )}
          <li className="how-to-add">
            <Link
              className={
                pathname ===
                  `/widgets/${widget.slug}/${platform?.slug}/how-to-add` ||
                pathname === `/widgets/${widget.slug}/how-to-add`
                  ? "active"
                  : ""
              }
              href={howToAddPath}
            >
              How to add
            </Link>
          </li>
          {!inView && (
            <>
              {widget.status !== "coming-soon" ? (
                <>
                  <li>
                    <Link
                      className={
                        pathname === `/widgets/${widget.slug}/pricing` ||
                        pathname ===
                          `/widgets/${widget.slug}/${platform?.slug}/pricing`
                          ? "active"
                          : ""
                      }
                      href={
                        isBrackets
                          ? `https://www.commoninja.com/embed/website/pricing/bracketsninja?mode=dark&via=commonninja`
                          : `/pricing`
                      }
                      target={isBrackets ? "_blank" : ""}
                    >
                      Pricing
                    </Link>
                  </li>
                  <li className="cta-li">
                    <CustomLink
                      external
                      title={`Free ${widget.name} ${
                        platform?.title ? `for ${platform.title}` : ""
                      }`}
                      href={`/${widget.slug}/editor`}
                      onClick={() => {
                        // trackEvent(`Try for free ${widget.name}`, { serviceName: widget.serviceName });
                      }}
                    >
                      Try for free
                    </CustomLink>
                  </li>
                </>
              ) : (
                <li className="cta-li">
                  <CustomLink
                    external
                    title={`Get Notified when ${widget.name} get released.`}
                    className="try-button"
                    onClick={() => {
                      // trackEvent(`Coming Soon Landing page ${widget.name}`);
                      if (setFormPopupOpen) {
                        setFormPopupOpen(true);
                      }
                    }}
                  >
                    Get Notified
                  </CustomLink>
                </li>
              )}
            </>
          )}
        </ul>
      </div>
    );
  }

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.location.pathname.includes("brackets")
    ) {
      setIsBrackets(true);
    } else {
      setIsBrackets(false);
    }

    // Toggle shake animation once every 5 seconds for 2 seconds
    const interval = setInterval(() => {
      setShake(true);
      setTimeout(() => {
        setShake(false);
      }, 1000);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <StyledWidgetNav
        ref={ref}
        className={`${inView || !loaded ? "show" : ""}`}
      >
        {renderNavContent()}
      </StyledWidgetNav>
      <StyledWidgetNav className={`fixed ${!inView && loaded ? "show" : ""}`}>
        {renderNavContent(true)}
      </StyledWidgetNav>
      <StyledFloatingButton
        className={`floating-button ${shake ? "shake" : ""}`}
        style={{ display: !inView ? "block" : "none" }}
        href={`/${widget.slug}/editor`}
        onClick={() => {
          trackEvent(`Floating try for free`, {
            serviceName: widget.serviceName,
          });
        }}
      >
        Try for free!
      </StyledFloatingButton>
    </>
  );
};
